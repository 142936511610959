import React from 'react';
import './App.css';
import GoblinCapitalist from './Containers/GoblinCapitalist/GoblinCapitalist';

function App() {
  return (
    <div className="App">
      <GoblinCapitalist/>
    </div>
  );
}

export default App;
