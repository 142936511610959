// Each piece of Material can be generated by one or more Components (not to be
// confused with a React component). At bare minimum, a Material has at least one
// component-- itself (purchased directly from the Auction House). However, materials
// can often be produced from other items. For example, you can mill 5 herbs to make
// pigment, or you can buy the pigment directly-- in this case, the pigment has two
// possible source components: herbs, or the pigment itself.
// This React component displays the value and name of those item components.

import React, { PureComponent } from 'react';
import classes from './Part.module.scss';

class Part extends PureComponent{
  resolvedPrices = () => {
    return this.props.items.map(item => {
      return (item[1] * this.props.marketPrices[item[0]]/10000).toFixed(2);
    });
  };
  sumPrice = () => {
    return this.resolvedPrices().reduce((total, next) => {
      return total + next;
    });
  };

  render(){
    if(this.sumPrice() === "0.00"){
      return (
        <div
          style={{'textDecoration': 'line-through'}}
          className={this.props.active === this.props.index ? classes.active : classes.part}>
          {`${this.props.display}: `}
          {`${this.sumPrice()}g`}
        </div>
      );
    } else {
      return (
        <div
          onClick={() => {this.props.changeActiveSource(this.props.context, this.props.index)}}
          className={this.props.active === this.props.index ? classes.active : classes.part}>
          {`${this.props.display}: `}
          {`${this.sumPrice()}g`}
        </div>
      );
    }
  }
}

export default Part;